import React, { useState, useEffect } from 'react';
import { View, FlatList, StyleSheet } from 'react-native';
import { RootTabScreenProps } from '../types';
import { useTranslation } from 'react-i18next';
import { useSector } from '../hooks/sectorContext';
import { getCourse } from '../hooks/mediaRouter';
import UnitModule from '../hooks/unitModule';

interface Unit {
    training_unit_id: string;
    training_unit_name: string;
    modules: Module[];
}

interface Module {
    training_module_id: string;
    title: string;
    description: string;
    terms: string;
}

interface Term {
    name: string;
    sector: string;
    description: string;
    translation: string;
    imgWord: string;
    imgDescription: string;
    video: string;
    videoDescription: string;
    img1: string;
    imgSource1: string;
    img2: string;
    imgSource2: string;
}

export default function TrainingScreen({ }: RootTabScreenProps<'Training'>) {

    // sector
    const { sector } = useSector();

    // lang
    const { i18n } = useTranslation();

    // units
    const [units, setUnits] = useState<Unit[]>([]);

    useEffect(() => {
        const loadCourse = async () => {
            try {
                const course = await getCourse(sector, i18n.language);

                const transformedUnits: Unit[] = course.units.map((unit: any) => ({
                    training_unit_id: unit.training_unit_id,
                    training_unit_name: unit.training_unit_name,
                    modules: unit.modules.map((module: any) => ({
                        training_module_id: module.training_module_id,
                        title: module.title,
                        description: module.description || '',
                        terms: module.terms,
                    })),
                }));

                setUnits(transformedUnits);
            } catch (error) {
                console.error('Error loading course:', error);
            }
        };
        loadCourse();
    }, [sector, i18n.language]);

    return (
        <View style={styles.container}>
            <FlatList
                data={units}
                renderItem={({ item }) => <UnitModule unit={item} />}
                keyExtractor={(item) => item.training_unit_id}
            />
        </View>
    );

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        padding: 10,
    },
});  