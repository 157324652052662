import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface Term {
  name: string;
  sector: string;
  description: string;
  translation: string;
  imgWord: string;
  imgDescription: string;
  video: string;
  videoDescription: string;
  img1: string;
  imgSource1: string;
  img2: string;
  imgSource2: string;
}

interface Progress {
  termName: string;
  learned: boolean;
}

const useProgress = (
  terms: Term[],
  moduleId: string
): [Progress[], (termName: string, learned: boolean) => void, () => void] => {
  const [progress, setProgress] = useState<Progress[]>([]);

  const updateProgress = async (termName: string, learned: boolean) => {
    const updatedProgress = progress.map((p) =>
      p.termName === termName ? { ...p, learned } : p
    );

    await AsyncStorage.setItem(`moduleProgress-${moduleId}`, JSON.stringify(updatedProgress));
    setProgress(updatedProgress);
  };

  const resetProgress = async () => {
    await AsyncStorage.removeItem(`moduleProgress-${moduleId}`);
    setProgress([]);
  };

  useEffect(() => {
    const loadProgress = async () => {
      const storedProgress = await AsyncStorage.getItem(`moduleProgress-${moduleId}`);
      if (storedProgress !== null) {
        setProgress(JSON.parse(storedProgress));
      } else {
        const initialProgress = terms.map((term) => ({
          termName: term.name,
          learned: false,
        }));
        setProgress(initialProgress);
      }
    };

    loadProgress();
  }, [moduleId, terms]);

  return [progress, updateProgress, resetProgress];
};

export default useProgress;
