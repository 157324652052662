import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { SuperMemoGrade } from '../hooks/superMemo';
// lang
import { useTranslation } from 'react-i18next';

interface Term {
  name: string;
  sector: string;
  description: string;
  translation: string;
  imgWord: string;
  imgDescription: string;
  video: string;
  videoDescription: string;
  img1: string;
  imgSource1: string;
  img2: string;
  imgSource2: string;
}

interface SelfAssessmentProps {
  currentItem: Term | null;
  onNext: (grade: SuperMemoGrade) => void;
}

const SelfAssessment: React.FC<SelfAssessmentProps> = ({ currentItem, onNext }) => {
  const { t } = useTranslation();

  const gradeButtons = [
    { grade: 0, label: t('training.no') },
    { grade: 3, label: t('training.meh') },
    { grade: 5, label: t('training.yes') },
  ].map(({ grade, label }) => (
    <TouchableOpacity
      key={grade}
      style={styles.gradeButton}
      onPress={() => onNext(grade as SuperMemoGrade)}
    >
      <Text style={styles.gradeButtonText}>{label}</Text>
    </TouchableOpacity>
  ));


  return (
    <View style={styles.container}>
      <Text style={styles.questionText}>{t('trainingVideo.trainingVideoText3')}</Text>
      <View style={styles.gradeButtonsContainer}>{gradeButtons}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  questionText: {
    fontSize: 26,
    fontWeight: 'bold',
    color: '#086e5d',
    marginTop: 15,
    paddingHorizontal: 15,
    textAlign: 'center',
  },
  gradeButtonsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  gradeButton: {
    backgroundColor: '#086e5d',
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 10,
    margin: 5,
    width: '80%'
  },
  gradeButtonText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#FFF',
  },
});

export default SelfAssessment;
