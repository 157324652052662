import React from 'react';
import { TouchableOpacity, View, Text, StyleSheet, FlatList } from 'react-native';
import { getStaticLangLabel } from './staticLabel';

const LanguageSelector = ({ languages, selectedLanguage, onLanguageChange }) => {
    const renderItem = ({ item }) => {
        return (
            <TouchableOpacity
                style={[
                    styles.item,
                    item === selectedLanguage && styles.selectedItem,
                ]}
                onPress={() => onLanguageChange(item)}
            >
                <Text style={styles.label}>{getStaticLangLabel(item)}</Text>
            </TouchableOpacity>
        );
    };

    return (
        <View style={styles.container}>
            <FlatList
                data={languages}
                renderItem={renderItem}
                keyExtractor={(item) => item}
                showsVerticalScrollIndicator={false}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
    },
    item: {
        padding: 10,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        marginBottom: 10,
    },
    selectedItem: {
        backgroundColor: '#15af97',
    },
    label: {
        fontSize: 18,
    },
});

export default LanguageSelector;