// react
import React, { useState, useEffect, useCallback } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Platform, Dimensions, useWindowDimensions } from 'react-native';

// modal status bar
import { StatusBar } from 'expo-status-bar';

//navigation
import { useRoute } from '@react-navigation/native';
import { TermScreenRouteProp } from '../../types';

// lang
import { useTranslation } from 'react-i18next';

// video
import { Video, ResizeMode } from 'expo-av';
import { getVideoBaseURI } from '../../hooks/mediaRouter';

const checkVideoExists = async (uri: string): Promise<boolean> => {
  try {
    const response = await fetch(uri, { method: 'HEAD' });
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

const TermScreen = () => {

  //navigation
  const route = useRoute<TermScreenRouteProp>();
  const { name, description, video, videoDescription } = route.params;

  //lang
  const { t, i18n } = useTranslation();

  //video base uri
  const base_uri = getVideoBaseURI(i18n.language);
  const { width, height } = Dimensions.get('window');
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const getVideoStyle = useCallback(() => {
    const isLandscape = windowWidth > windowHeight;

    return Platform.select({
      web: {
        width: '100%',
        aspectRatio: 16 / 9,
        maxHeight: windowHeight,
      },
      default: {
        width: isLandscape ? width / 2 : width,
        height: isLandscape ? height / 2 : height * 0.3,
      },
    });
  }, [windowWidth, windowHeight]);

  //video load
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [videoError, setVideoError] = useState(false);

  //video description load
  const [isVideoDescriptionLoaded, setIsVideoDescriptionLoaded] = useState(false);
  const [videoDescriptionError, setVideoDescriptionError] = useState(false);
  const [showVideoDescription, setShowVideoDescription] = useState(false);

  //video exists
  useEffect(() => {
    const checkVideos = async () => {
      const videoCheck = await checkVideoExists(base_uri + video);
      const videoDescriptionCheck = await checkVideoExists(videoDescription);
    };

    checkVideos();
  }, [video, videoDescription]);

  //handle video load
  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  //handle video description load
  const handleVideoDescriptionLoad = () => {
    setIsVideoDescriptionLoaded(true);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isVideoLoaded) {
        setVideoError(true);
      }
      if (!isVideoDescriptionLoaded) {
        setVideoDescriptionError(true);
      }
    }, 15000);

    return () => clearTimeout(timeout);
  }, [isVideoLoaded, isVideoDescriptionLoaded]);

  return (
    <View style={styles.screenContainer}>
      <View style={styles.header}>

        {/*Term Video */}
        <Text style={styles.screenTitleText}>{name}</Text>

        <View style={styles.videoContainer}>
          <Video
            source={{ uri: base_uri + video }}
            style={[styles.video, getVideoStyle()]}
            isLooping
            shouldPlay
            onLoad={handleVideoLoad}
            onError={() => setVideoError(true)}
            resizeMode={ResizeMode.CONTAIN}
          />
          {videoError && (
            <Text style={styles.screenDescriptionText}>
              Error: The video could not be found or did not load within 15 seconds.
            </Text>
          )}
        </View>
      </View>
      <View style={styles.headerDescription}>

        <Text style={styles.sectionDescriptionText}>{t('glossaryTerm.glossaryTermDescription')}</Text>
        <Text style={styles.sectionDescriptionTitleText}>{description}</Text>

        {!showVideoDescription && (
          <TouchableOpacity onPress={() => setShowVideoDescription(!showVideoDescription)} style={styles.showDescriptionButton}>
            <Text style={styles.screenDescriptionText}>{t('glossaryTerm.glossaryTermText1')}</Text>
          </TouchableOpacity>
        )}

        {showVideoDescription && (

          <View style={styles.videoContainer}>
            <Video
              source={{ uri: base_uri + videoDescription }}
              style={[styles.video, getVideoStyle()]}
              useNativeControls={isVideoDescriptionLoaded}
              onLoad={handleVideoDescriptionLoad}
              onError={() => setVideoDescriptionError(true)}
              resizeMode={ResizeMode.CONTAIN}
            />
            {videoError && (
              <Text style={styles.screenDescriptionText}>
                Error: The video could not be found or did not load within 15 seconds.
              </Text>
            )}
          </View>

        )}
      </View>


      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
    </View>
  );
}

export default TermScreen;

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  header: {
    backgroundColor: '#15af97',
    width: '100%',
    marginBottom: 20,
  },
  headerDescription: {
    backgroundColor: 'black',
    width: '100%',
    marginBottom: 20,
  },
  screenTitleText: {
    padding: 10,
    fontSize: 30,
    color: 'white',
    fontWeight: 'bold'
  },
  screenDescriptionText: {
    fontSize: 10,
    fontWeight: 'bold',
    padding: 5,
    margin: 5,
    color: 'white'
  },
  sectionContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    padding: 5,
    margin: 5,
  },
  sectionTitleText: {
    fontSize: 20,
    fontWeight: 'bold',
    padding: 2,
    margin: 2
  },
  sectionDescriptionTitleText: {
    fontSize: 16,
    padding: 2,
    margin: 2,
    color: 'white'
  },
  sectionDescriptionText: {
    fontSize: 14,
    padding: 2,
    margin: 2,
    color: 'white'
  },
  buttonContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    height: 50,
    width: 200,
    padding: 5,
    margin: 5,
    backgroundColor: '#cacaca50',
    borderRadius: 10,
    textAlignVertical: 'center'
  },
  buttonText: {
    fontSize: 15,
    fontWeight: 'bold',
    padding: 20,
  },
  buttonListContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: 15,
    marginTop: 15,
    backgroundColor: '#cacaca50',
    borderRadius: 10,
    textAlignVertical: 'center'
  },
  buttonListTitle: {
    fontSize: 25,
    fontWeight: 'bold',
    padding: 10,
    margin: 10,
  },
  buttonListDescription: {
    padding: 5,
  },
  searchbar: {
    backgroundColor: '#fafafa',
    marginStart: 10,
    marginEnd: 10,
    marginVertical: 10,
    borderRadius: 10,
    padding: 10
  },
  textinput: {
    backgroundColor: '#fafafa',
    paddingHorizontal: 20
  },
  videoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: 15,
  },
  video: {
    width: '100%',
    height: '100%',
  },
  showDescriptionButton: {
    backgroundColor: '#086e5d',
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    margin: 20,
    marginBottom: 20,
    alignSelf: 'center',
  },
});