import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

interface ProgressBarProps {
  percentage: number;
  color: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percentage, color }) => {
  const progressBarWidth = percentage > 0 ? percentage : 0;
  return (
    <View style={styles.progressBarContainer}>
      <View style={[styles.progressBar, { width: `${progressBarWidth}%`, backgroundColor: color }]} />
      <Text style={styles.percentageText}>{`${Math.round(percentage)}%`}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  progressBarContainer: {
    width: '80%',
    height: 30,
    backgroundColor: '#fffaf5',
    borderRadius: 5,
    borderColor: '#B2BDCD',
    marginVertical: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  progressBar: {
    height: '100%',
    borderRadius: 5,
    position: 'absolute',
    left: 0,
    top: 0,
  },
  percentageText: {
    fontSize: 14,
    color: '#a0dbd1',
    fontWeight: 'bold',
    zIndex: 1,
  },
});

export default ProgressBar;
