//react
import React, { useState, useEffect } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, TextInput, FlatList } from 'react-native';

//navigation
import { useNavigation } from '@react-navigation/native';
import { GlossaryScreenNavigationProp, RootTabScreenProps } from '../types';

//lang
import { useTranslation } from 'react-i18next';

//sector
import { useSector } from '../hooks/sectorContext';

//terms
import { getTerms } from '../hooks/mediaRouter';

interface Term {
    name: string;
    sector: string;
    description: string;
    translation: string;
    imgWord: string;
    imgDescription: string;
    video: string;
    videoDescription: string;
    img1: string;
    imgSource1: string;
    img2: string;
    imgSource2: string;
}

export default function GlossaryScreen({ }: RootTabScreenProps<'Glossary'>) {

    //navigation
    const navigation = useNavigation<GlossaryScreenNavigationProp>();

    //sector
    const { sector } = useSector();

    //lang
    const { t, i18n } = useTranslation();

    //glossary lang
    const $sector_str = t('settingsAppSector.' + sector);
    const title_str = t('glossary.glossaryTitle');
    const description_str = t('glossary.glossaryDescription');
    const search_str = t('glossary.glossarySearch');

    //terms
    const [terms, setTerms] = useState<Term[]>([]);

    useEffect(() => {
        const loadTerms = async () => {
            try {
                const termsModule = await getTerms(sector, i18n.language);
                setTerms(termsModule.terms);
            } catch (error) {
                console.error('Error loading terms:', error);
            }
        };
        loadTerms();
    }, [sector, i18n.language]);

    //search string
    const [search, setSearch] = useState('');

    //filter
    const filterTerms = (term: Term) => {
        return term.name.toLowerCase().includes(search.toLowerCase());
    };

    //render item
    const renderItem = ({ item }: { item: Term }) => {
        return (
            <TouchableOpacity
                onPress={() => navigation.navigate('Term', { name: item.name, description: item.description, video: item.video, videoDescription: item.videoDescription })}
                style={styles.termItem}
            >
                {/* term name */}
                <Text style={styles.termName}>{item.name}</Text>

                {/* term description */}
                <Text style={styles.termDescription}>{item.description}</Text>

            </TouchableOpacity>
        );
    };

    //sector and language filtered terms
    const filteredTerms = terms.filter(filterTerms);
    //sorted terms
    const sortedFilteredTerms = filteredTerms.sort((a, b) => a.name.localeCompare(b.name));

    return (
        <View style={styles.container}>
            <View style={styles.headerContainer}>

                {/* sector (dynamic) */}
                <Text style={styles.title}>{$sector_str}</Text>

                {/* description */}
                <Text style={styles.description}>{description_str}</Text>

                {/* search bar */}
                <TextInput
                    style={styles.searchBar}
                    onChangeText={setSearch}
                    value={search}
                    placeholder={search_str}
                />
            </View>

            {/* language, sector and user filtered and alphabetically ordered list of terms*/}
            <FlatList
                data={sortedFilteredTerms}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
            />

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        padding: 10,
    },
    headerContainer: {
        backgroundColor: '#15af97',
        padding: 20,
        borderRadius: 20,
        marginBottom: 20,
    },
    title: {
        padding: 10,
        fontSize: 30,
        color: 'white',
        fontWeight: 'bold'
    },
    sectorName: {
        padding: 10,
        fontSize: 25,
    },
    description: {
        padding: 10,
        fontSize: 17,
        color: 'white',
        marginBottom: 5
    },
    searchBar: {
        borderWidth: 1,
        backgroundColor: 'white',
        borderColor: '#ccc',
        borderRadius: 5,
        padding: 10,
        marginBottom: 10,
        color: 'black',
    },
    termItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#15af97',
    },
    termName: {
        padding: 5,
        fontSize: 15,
        fontWeight: 'bold'
    },
    termDescription: {
        padding: 5,
        fontSize: 15
    }
});