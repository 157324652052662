import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './app/app_en.json';
import de from './app/app_de.json';
import es from './app/app_es.json';
import oe from './app/app_oe.json';
import it from './app/app_it.json';

const resources = {
  en: {
    translation: en
  },
  es: {
    translation: es
  },
  de: {
    translation: de
  },
  oe: {
    translation: oe
  },
  it: {
    translation: it
  },
};

i18n
  .use(initReactI18next)
  .init({
    lng: 'en',
    resources,
    react: {
      useSuspense: false
    }
  });

export default i18n;
