//react
import React, { useState } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import LanguageSelector from '../../hooks/LanguageSelector';

//lang
import { useTranslation } from 'react-i18next';
import { getStaticLangLabel } from '../../hooks/staticLabel';
const languages = ['en', 'es', 'de', 'it', 'oe'];

//navigation
import { useRoute } from '@react-navigation/native';
import { AppLanguageScreenRouteProp } from '../../types';

const AppLanguageScreen = () => {

  //navigation
  const route = useRoute<AppLanguageScreenRouteProp>();

  //lang
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  //lang lang
  const lang_selected = t('settings.settingsSelectedLang');
  const description_str = t('settings.settingsLangDescription');

  //change language method
  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        {/* app sector */}
        <Text style={styles.description}>{lang_selected}</Text>

        {/* selected sector */}
        <Text style={styles.title}>{getStaticLangLabel(selectedLanguage)}</Text>

        {/* sector description */}
        <Text style={styles.description}>{description_str}</Text>

        <View style={styles.container}>
          {/* selector */}
          <LanguageSelector
            languages={languages}
            selectedLanguage={selectedLanguage}
            onLanguageChange={handleLanguageChange}
          />
        </View>
      </View>

    </View>
  );

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
  },
  header: {
    backgroundColor: '#15af97',
    padding: 20,
    borderRadius: 20,
    marginBottom: 20,
    height: '100%',
  },
  title: {
    fontSize: 28,
    fontWeight: '600',
    marginBottom: 15,
    textAlign: 'center',
    color: 'white'
  },
  sectorName: {
    fontSize: 25,
    fontWeight: '600',
    marginBottom: 15,
    textAlign: 'center',
  },
  description: {
    fontSize: 18,
    lineHeight: 24,
    marginBottom: 15,
    textAlign: 'center',
  },
  picker: {
    flex: 2,
    padding: 2,
    color: '#333',
    justifyContent: 'center',
    borderRadius: 20,
    borderWidth: 15,
    borderColor: 'white'
  },
});

export default AppLanguageScreen;
