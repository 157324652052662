import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity, Platform, StyleSheet, Dimensions, useWindowDimensions } from 'react-native';

import { RootTabScreenProps } from '../../types';
import { useNavigation } from '@react-navigation/native';

// lang
import { useTranslation } from 'react-i18next';

// video
import { Video, ResizeMode } from 'expo-av';
import { getVideoBaseURI } from '../../hooks/mediaRouter';

//progress
import useConvertStringToTerms from '../../hooks/useConvertStringToTerms';
import SelfAssessment from '../../hooks/selfAssessment';
import { SuperMemoGrade } from '../../hooks/superMemo';
import ProgressBar from '../../hooks/ProgressBar';

import useProgress from '../../hooks/useProgress';
import useCompletionPercentage from '../../hooks/useCompletionPercentage';
import useNextTermToLearn from '../../hooks/useNextTermToLearn';

interface Term {
  name: string;
  sector: string;
  description: string;
  translation: string;
  imgWord: string;
  imgDescription: string;
  video: string;
  videoDescription: string;
  img1: string;
  imgSource1: string;
  img2: string;
  imgSource2: string;
}

const checkVideoExists = async (uri: string): Promise<boolean> => {
  try {
    const response = await fetch(uri, { method: 'HEAD' });
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

const TrainingVideoScreen = ({ route }: RootTabScreenProps<'TrainingVideo'>) => {

  const [showTrainingVideo, setshowTrainingVideo] = useState(true);

  const navigation = useNavigation();

  const { t, i18n } = useTranslation();

  const { title, description, terms: termsString } = route.params;

  const [terms, unmatchedTerms] = useConvertStringToTerms(termsString);

  const [showEvaluation, setShowEvaluation] = useState(false);

  const moduleId = title;

  const [progress, updateProgress, resetProgress] = useProgress(terms, moduleId);
  const completionPercentage = useCompletionPercentage(progress, terms);
  const nextTermToLearn = useNextTermToLearn(terms, progress);

  const currentTerm = nextTermToLearn?.name;
  const video = nextTermToLearn?.video;

  const base_uri = getVideoBaseURI(i18n.language);
  const { width, height } = Dimensions.get('window');
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const getVideoStyle = useCallback(() => {
    const isLandscape = windowWidth > windowHeight;

    return Platform.select({
      web: {
        height: windowWidth * (9 / 16),
      },
      default: {
        width: isLandscape ? width / 2 : width,
        height: isLandscape ? height / 2 : height * 0.3,
      },
    });
  }, [windowWidth, windowHeight]);


  //video load
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [videoError, setVideoError] = useState(false);

  //video exists
  useEffect(() => {
    const checkVideos = async () => {
      const videoCheck = await checkVideoExists(base_uri + video);
    };

    checkVideos();
  }, [video]);

  //handle video load
  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isVideoLoaded) {
        setVideoError(true);
      }
    }, 15000);

    return () => clearTimeout(timeout);
  }, [isVideoLoaded]);

  const handleNext = (grade: SuperMemoGrade) => {
    if (currentTerm) {

      if (grade === 5) {
        updateProgress(currentTerm, true);
      } else {
        updateProgress(currentTerm, false);
      }
    }

    if (!currentTerm) {
      navigation.goBack();
      return;
    }

    setShowEvaluation(false);
    if (showTrainingVideo) {
      setshowTrainingVideo(false);
    } else {
      setshowTrainingVideo(true);
    }
  };

  const [moduleComplete, setModuleComplete] = useState(false);
  useEffect(() => {
    if (completionPercentage === 100) {
      setModuleComplete(true);
      setTimeout(() => {
        navigation.navigate('Training');
      }, 3000);
    }
  }, [completionPercentage, navigation]);


  return (
    <>
      {moduleComplete ? (
        <View style={styles.moduleCompleteContainer}>
          <Text style={styles.moduleCompleteText}>
            {t('training.congratulations')}
          </Text>
        </View>
      ) : (
        <>
          {showTrainingVideo && (

            <View style={styles.container}>

              <ProgressBar percentage={completionPercentage} color={'#086e5d'} />
              {!showEvaluation && (
                <Text style={styles.description}>{t('trainingVideo.trainingVideoText1')}</Text>
              )}

              {showEvaluation && (
                <View style={styles.container}>
                  <Text style={styles.description}>{t('trainingVideo.trainingVideoText4')}</Text>
                  <Text style={styles.title}>{currentTerm}</Text>
                </View>
              )}

              <View style={styles.videoContainer}>
                <Video
                  source={{ uri: base_uri + video }}
                  style={[styles.video, getVideoStyle()]}
                  isLooping
                  shouldPlay
                  onLoad={handleVideoLoad}
                  onError={() => setVideoError(true)}
                  resizeMode={ResizeMode.CONTAIN}
                />
                {videoError && (
                  <Text style={styles.description}>
                    Error: The video could not be found or did not load within 15 seconds.
                  </Text>
                )}
              </View>

              {!showEvaluation && (
                <TouchableOpacity
                  style={styles.buttonContainer}
                  onPress={() => setShowEvaluation(true)}
                >
                  <Text style={styles.buttonText}>{t('trainingVideo.trainingVideoText2')}</Text>
                </TouchableOpacity>
              )}

              {showEvaluation && (
                <SelfAssessment
                  currentItem={nextTermToLearn}
                  onNext={handleNext}
                />
              )}

            </View>
          )}
          {!showTrainingVideo && (

            <View style={styles.container}>

              <ProgressBar percentage={completionPercentage} color={'#086e5d'} />
              {!showEvaluation && (
                <Text style={styles.description}>{t('trainingText.trainingTextText1')}</Text>
              )}
              {!showEvaluation && (
                <Text style={styles.title}>{currentTerm}</Text>
              )}


              {showEvaluation && (
                <><Text style={styles.title}>{currentTerm}</Text>
                  <View style={styles.videoContainer}>
                    <Video
                      source={{ uri: base_uri + video }}
                      style={[styles.video, getVideoStyle()]}
                      isLooping
                      shouldPlay
                      onLoad={handleVideoLoad}
                      onError={() => setVideoError(true)}
                      resizeMode={ResizeMode.CONTAIN} />
                    {videoError && (
                      <Text style={styles.description}>
                        Error: The video could not be found or did not load within 15 seconds.
                      </Text>
                    )}
                  </View></>
              )}



              {!showEvaluation && (
                <TouchableOpacity
                  style={styles.buttonContainer}
                  onPress={() => setShowEvaluation(true)}
                >
                  <Text style={styles.buttonText}>{t('trainingVideo.trainingVideoText2')}</Text>
                </TouchableOpacity>
              )}

              {showEvaluation && (
                <SelfAssessment
                  currentItem={nextTermToLearn}
                  onNext={handleNext}
                />
              )}

            </View>
          )}
        </>)}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
    alignItems: 'center',
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    color: '#086e5d',
    marginBottom: 15,
    paddingHorizontal: 15,
    textAlign: 'center',
  },
  description: {
    fontSize: 20,
    color: '#086e5d',
    marginBottom: 25,
    paddingHorizontal: 15,
    textAlign: 'center',
  },
  termContainer: {
    backgroundColor: '#e0fffa',
    borderRadius: 5,
    padding: 15,
    marginBottom: 15,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    width: '100%',
  },
  termName: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#086e5d',
    marginTop: 10,
  },
  termDescription: {
    fontSize: 16,
    color: '#086e5d',
    marginTop: 10,
    textAlign: 'center',
  },
  buttonContainer: {
    backgroundColor: '#086e5d',
    padding: 10,
    margin: 20,
    borderRadius: 5,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1,
    elevation: 5,
  },
  buttonText: {
    fontSize: 15,
    fontWeight: 'bold',
    padding: 20,
    color: 'white'
  },
  videoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: 15,
  },
  video: {
    width: '100%',
    height: '100%',
  },
  moduleCompleteContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#086e5d',
  },
  moduleCompleteText: {
    fontSize: 24,
    color: 'white',
    textAlign: 'center',
    paddingHorizontal: 20,
  },
});

export default TrainingVideoScreen;
