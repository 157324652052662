import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, StyleSheet, TouchableOpacity } from 'react-native';
import { RootTabScreenProps, TrainingScreenNavigationProp } from '../../types';
import { useNavigation } from '@react-navigation/native';

import { ModuleScreenRouteProp } from '../../types';
import { useTranslation } from 'react-i18next';

import useConvertStringToTerms from '../../hooks/useConvertStringToTerms';
import useCompletionPercentage from '../../hooks/useCompletionPercentage';
import useProgress from '../../hooks/useProgress';

import ProgressBar from '../../hooks/ProgressBar';

interface Term {
    name: string;
    sector: string;
    description: string;
    translation: string;
    imgWord: string;
    imgDescription: string;
    video: string;
    videoDescription: string;
    img1: string;
    imgSource1: string;
    img2: string;
    imgSource2: string;
}

interface ModuleScreenProps {
    route: ModuleScreenRouteProp;
}

const ModuleScreen: React.FC<ModuleScreenProps> = ({ route }) => {

    const navigation = useNavigation<TrainingScreenNavigationProp>();

    const { t } = useTranslation();

    const { title, description, terms: termsString } = route.params;

    const [terms, unmatchedTerms] = useConvertStringToTerms(termsString);

    //progress
    const moduleId = title;

    const [progress, updateProgress, resetProgress] = useProgress(terms, moduleId);
    const completionPercentage = useCompletionPercentage(progress, terms);

    return (
        <View style={styles.container}>
            {unmatchedTerms.length > 0 && (
                <Text style={styles.unmatchedTermsInfo}>
                    {t('notFoundTerms') + unmatchedTerms.join(', ')}
                </Text>
            )}
            <View style={styles.header}>
                <Text style={styles.title}>{title}</Text>
                <Text style={styles.description}>{description}</Text>
                {terms.length > 0 && (
                    <>

                        <Text style={styles.progressTitle}>{t('training.learningProgress')}</Text>
                        <ProgressBar
                            key={completionPercentage}
                            percentage={completionPercentage}
                            color={'#086e5d'}
                        />

                        <TouchableOpacity style={styles.resetProgressButton} onPress={resetProgress}>
                            <Text style={styles.resetProgressButtonText}>
                                {t('trainingModule.trainingModuleText3')}
                            </Text>
                        </TouchableOpacity>
                    </>
                )}
            </View>

            {terms.length > 0 && completionPercentage < 100 && (
                <TouchableOpacity
                    style={styles.completeTermButton}
                    onPress={() =>
                        navigation.navigate('TrainingVideo', {
                            training_module_id: moduleId,
                            title: title,
                            description: description,
                            terms: termsString,
                        })
                    }
                >
                    <Text style={styles.completeTermButtonText}>
                        {completionPercentage == 1
                            ? t('trainingModule.trainingModuleText3')
                            : completionPercentage == 0
                                ? t('trainingModule.trainingModuleText1')
                                : t('trainingModule.trainingModuleText2')}
                    </Text>
                </TouchableOpacity>
            )}

            <View style={styles.flatListContainer}>

                <Text style={styles.expressions}>{t('trainingModule.trainingModuleTitle')}</Text>

                <FlatList
                    contentContainerStyle={styles.flatListContentContainer}
                    data={terms}
                    renderItem={({ item }) => (
                        <View style={styles.termContainer}>
                            <Text style={styles.termName}>{item.name}</Text>
                            <Text style={styles.termDescription}>{item.description}</Text>
                        </View>
                    )}
                    keyExtractor={(item, index) => index.toString()}
                />
            </View>


        </View>
    );

};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    flatListContainer: {
        flex: 2,
    },
    flatListContentContainer: {
        paddingHorizontal: 10,
    },
    header: {
        backgroundColor: '#15af97',
        padding: 20,
        marginBottom: 20,
        alignItems: 'center',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'white',
        marginTop: 5,
    },
    description: {
        fontSize: 15,
        color: 'white',
        marginTop: 10,
    },
    termContainer: {
        backgroundColor: '#e0fffa',
        borderRadius: 5,
        padding: 10,
        marginBottom: 10,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    termName: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'black',
        marginTop: 10
    },
    termDescription: {
        fontSize: 14,
        color: 'black',
        marginTop: 10
    },
    unmatchedTermsInfo: {
        fontSize: 16,
        color: 'white',
        textAlign: 'center',
        backgroundColor: '#d48585'
    },
    nextTermToLearn: {
        fontSize: 18,
        color: '#4F5D75',
        marginBottom: 10,
        textAlign: 'center',
    },
    completeTermButton: {
        backgroundColor: '#086e5d',
        borderRadius: 5,
        paddingHorizontal: 40,
        paddingVertical: 20,
        marginBottom: 20,
        alignSelf: 'center',
    },
    completeTermButtonText: {
        fontSize: 18,
        color: '#fff',
    },
    resetProgressButton: {
        backgroundColor: '#a84632',
        borderRadius: 5,
        paddingHorizontal: 15,
        paddingVertical: 5,
        marginBottom: 20,
        marginTop: 10,
        alignSelf: 'center',
    },
    resetProgressButtonText: {
        fontSize: 8,
        color: '#fff',
    },
    progressTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'white',
        marginTop: 20
    },
    expressions: {
        alignSelf: 'center',
        fontSize: 18,
        color: '#15af97',
        marginBottom: 5,
    }
});

export default ModuleScreen;