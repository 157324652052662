import { useMemo } from 'react';

interface Term {
    name: string;
    sector: string;
    description: string;
    translation: string;
    imgWord: string;
    imgDescription: string;
    video: string;
    videoDescription: string;
    img1: string;
    imgSource1: string;
    img2: string;
    imgSource2: string;
}

interface Progress {
    termName: string;
    learned: boolean;
}

const useNextTermToLearn = (terms: Term[], progress: Progress[]) => {
    return useMemo(() => {
        const unlearnedTerms = terms.filter((term) => {
            const termProgress = progress.find((p) => p.termName === term.name);
            return !termProgress || !termProgress.learned;
        });

        const randomIndex = Math.floor(Math.random() * unlearnedTerms.length);

        return unlearnedTerms[randomIndex] || null;
    }, [terms, progress]);
};

export default useNextTermToLearn;
