import React, { createContext, useState, useContext, ReactNode } from 'react';

type sectorContextType = {
  sector: string;
  setSector: (sector: string) => void;
};

const sectorContext = createContext<sectorContextType>({ sector: 'cs', setSector: () => {} });

export const useSector = () => useContext(sectorContext);

export const SectorProvider = ({ children }: {children: ReactNode}) => {
  const [sector, setSector] = useState('hs');

  return (
    <sectorContext.Provider value={{ sector, setSector }}>
      {children}
    </sectorContext.Provider>
  );
};