//react
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Picker } from '@react-native-picker/picker';

//lang
import { useTranslation } from 'react-i18next';
import { getStaticSectorLabel } from '../../hooks/staticLabel';

//sector
import { useSector } from '../../hooks/sectorContext';

//navigation
import { useRoute } from '@react-navigation/native';
import { AppSectorScreenRouteProp } from '../../types';
import Selector from '../../hooks/SectorSelector';

//static sector ids
const sectors = ['cs', 'hs', 'sp', 'tg', 'wa'];

const AppSector = () => {

  //navigation
  const route = useRoute<AppSectorScreenRouteProp>();

  //sector
  const { sector, setSector } = useSector();

  //lang
  const { t, i18n } = useTranslation();

  //sector lang
  const $sector_str = t('settingsAppSector.' + sector);
  const sector_selected = t('settings.settingsSelectedSector');
  const description_str = t('settings.settingsAppSectorDescription');

  //sector handler
  const handleSelectSector = (selectedSector: string) => {
    setSector(selectedSector);
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        {/* app lang */}
        <Text style={styles.description}>{sector_selected}</Text>

        {/* selected sector */}
        <Text style={styles.title}>{$sector_str}</Text>

        {/* sector description */}
        <Text style={styles.description}>{description_str}</Text>
        {/* selector */}
        <View style={styles.container}>
          <Selector
            items={sectors}
            selectedItem={sector}
            onItemChange={handleSelectSector}
            getItemLabel={(sector) => getStaticSectorLabel(sector, i18n.language)}
          />
        </View>
      </View>

    </View>

  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
  },
  header: {
    backgroundColor: '#15af97',
    padding: 20,
    borderRadius: 20,
    marginBottom: 20,
    height: '100%',
  },
  title: {
    fontSize: 28,
    fontWeight: '600',
    marginBottom: 15,
    textAlign: 'center',
    color: 'white'
  },
  sectorName: {
    fontSize: 25,
    fontWeight: '600',
    marginBottom: 15,
    textAlign: 'center',
  },
  description: {
    fontSize: 18,
    lineHeight: 24,
    marginBottom: 15,
    textAlign: 'center',
  },
  picker: {
    flex: 2,
    padding: 2,
    color: '#333',
    justifyContent: 'center',
    borderRadius: 20,
    borderWidth: 15,
    borderColor: 'white'
  },
});

export default AppSector;