//react
import React, { useState } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Image } from 'react-native';

//navigation
import { useNavigation } from '@react-navigation/native';
import { HomeScreenNavigationProp } from '../types';
import { RootTabScreenProps } from '../types';

//lang
import { useTranslation } from 'react-i18next';
import { getStaticSectorLabel } from '../hooks/staticLabel';

//sector
import { useSector } from '../hooks/sectorContext';

//logo
import swingLogoWhite from '../assets/swing_logo_white.png';

export default function HomeScreen({ }: RootTabScreenProps<'Home'>) {

    //navigation
    const navigation = useNavigation<HomeScreenNavigationProp>();

    //lang
    const { t, i18n } = useTranslation();

    //sector
    const { sector } = useSector();

    //sector lang
    const sector_selected = getStaticSectorLabel(sector, i18n.language);
    const sector_str = t('settings.settingsAppSectorTitle');

    return (
        <View style={styles.container}>

            <View style={styles.logoContainer}>

                <Image
                    source={swingLogoWhite}
                    style={styles.logo}
                />
            </View>
            {/*Home title
            <Text style={styles.title}>
                {t('home.welcomeTitle')}
            </Text>
            */}

            <View style={styles.container}>

                {/*Home Description*/}
                <Text style={styles.description}>
                    {t('home.welcomeText1')}
                </Text>

                <Text style={styles.description}>
                    {t('home.welcomeText2')}
                </Text>

                <View style={styles.selectedSector}>
                    {/*Sector title*/}
                    <Text style={styles.sectorName}>
                        {t('settingsAppSector.settingsAppSector')}
                    </Text>
                    <Text style={styles.selectedSector}>
                        {sector_selected}
                    </Text>
                </View>

                <Text style={styles.description}>
                    {t('home.welcomeText3')}
                </Text>


                <View style={styles.footerContainer}>

                    {/*Legal Notice Link*/}
                    <TouchableOpacity
                        style={styles.footerButton}
                        onPress={() => navigation.navigate('DataProtection')}>
                        <Text style={styles.footerText}>{t('dataProtection.dataProtection')}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={styles.footerButton}
                        onPress={() => navigation.navigate('Settings')}>
                        <Text style={styles.footerText}>{t('settings.settingsLangButton')}</Text>
                    </TouchableOpacity>
                    {/*Project Information Link*/}


                    {/*
                    <TouchableOpacity
                        style={styles.footerButton}
                        onPress={() => navigation.navigate('ProjectInfo')}>
                        <Text style={styles.footerText}>{t('projectInfo.projectInfo')}</Text>
                    </TouchableOpacity>
                <TouchableOpacity
                    style={styles.footerButton}
                    onPress={() => navigation.navigate('DataProtection')}>
                    <Text style={styles.footerText}>{t('dataProtection.dataProtection')}</Text>
                </TouchableOpacity>
                */}

                </View>
                <View style={styles.footerContainer}>
                    <Image
                        source={{ uri: 'https://www.swing.infoproject.eu/wp-content/uploads/2018/02/e-300x65.png' }}
                        style={styles.logoEU}
                    />

                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#15af97',
        padding: 10,
    },
    selectedSector: {
        padding: 10,
        backgroundColor: 'white',
        borderRadius: 20,
        alignItems: 'center',
        borderWidth: 5,
        borderColor: 'white',
        fontSize: 30,
        fontWeight: 'bold',
        color: 'black'
    },
    footerContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        height: '8%'
    },
    title: {
        fontSize: 30,
        fontWeight: 'bold',
        borderWidth: 5,
        borderColor: 'white',
        borderRadius: 20,
        padding: 5,
        margin: 5,
        color: '#fff',
    },
    sectorName: {
        fontSize: 20,
        fontWeight: '600',
        margin: 10,
        color: '#086e5d',
    },
    description: {
        fontSize: 18,
        marginVertical: 10,
        color: 'white',
        marginTop: 20,
    },
    button: {
        backgroundColor: '#FF5722',
        borderRadius: 20,
        padding: 12,
        borderWidth: 5,
        borderColor: 'white',
        marginBottom: 5,
    },
    buttonText: {
        color: '#fff',
        fontSize: 18,
        textAlign: 'center',
    },
    footerButton: {
        padding: 10,
    },
    footerText: {
        fontSize: 14,
        textDecorationLine: 'underline',
        color: '#fff',
    },
    logoContainer: {
        height: '15%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 15,
        marginTop: 25,
    },
    logo: {
        width: '100%',
        height: '100%',
        resizeMode: 'contain',
    },
    logoEU: {
        width: '80%',
        height: '80%',
        resizeMode: 'contain',
    },
});
