//react
import React, { useState } from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';

//navigation
import { useNavigation } from '@react-navigation/native';
import { SettingsScreenNavigationProp } from '../types';
import { RootTabScreenProps } from '../types';

//lang
import { useTranslation } from 'react-i18next';
import { getStaticLangLabel, getStaticSectorLabel } from '../hooks/staticLabel';

//sector
import { useSector } from '../hooks/sectorContext';

//progress
import AsyncStorage from '@react-native-async-storage/async-storage';


export default function SettingsScreen({ }: RootTabScreenProps<'Settings'>) {

  //navigation
  const navigation = useNavigation<SettingsScreenNavigationProp>();

  //lang
  const { t, i18n } = useTranslation();

  //sector
  const { sector } = useSector();

  //lang lang
  const lang_selected = getStaticLangLabel(i18n.language);
  const lang_str = t('settings.settingsSelectedLang');

  //sector lang
  const sector_selected = getStaticSectorLabel(sector, i18n.language);
  const sector_str = t('settings.settingsAppSectorTitle');

  //reset all progress
  const resetAllAsyncStorage = async () => {
    const keys = await AsyncStorage.getAllKeys();
    const moduleKeys = keys.filter((key) => key.includes('moduleProgress-'));
    await AsyncStorage.multiRemove(moduleKeys);
  };

  return (
    <View style={styles.container}>


      {/*change language*/}
      <View style={styles.headerContainer}>
        {/* selected lang */}
        <Text style={styles.description}>{lang_str}</Text>
        <Text style={styles.title}>{lang_selected}</Text>

        <TouchableOpacity
          style={styles.button}
          onPress={() => navigation.navigate('AppLanguage')}>
          <Text style={styles.buttonText}>{t('settings.settingsLangButton')}</Text>
        </TouchableOpacity>
      </View>


      {/*change sector*/}
      <View style={styles.headerContainer}>
        {/* selected sector */}
        <Text style={styles.description}>{sector_str}</Text>
        <Text style={styles.title}>{sector_selected}</Text>

        <TouchableOpacity
          style={styles.button}
          onPress={() => navigation.navigate('AppSector')}>
          <Text style={styles.buttonText}>{t('settings.settingsAppSectorButton')}</Text>
        </TouchableOpacity>
      </View>

      {/*reset all learning progress*/}
      <View style={styles.headerContainer}>
        <Text style={styles.description}>{t('settings.settingsResetAllProgress1')}</Text>
        <Text style={styles.description}>{t('settings.settingsResetAllProgress2')}</Text>
        <TouchableOpacity
          style={styles.buttonRed}
          onPress={resetAllAsyncStorage}>
          <Text style={styles.resetProgressButtonText}>{t('settings.settingsResetAllProgress1')}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 10,
  },
  headerContainer: {
    backgroundColor: '#15af97',
    padding: 20,
    borderRadius: 20,
    marginBottom: 20,
  },
  title: {
    fontSize: 28,
    fontWeight: '600',
    marginBottom: 15,
  },
  sectorName: {
    fontSize: 25,
    fontWeight: '600',
    marginBottom: 15,
    color: '#fff',

  },
  description: {
    fontSize: 18,
    lineHeight: 24,
    marginBottom: 15,
    color: '#fff',

  },
  button: {
    backgroundColor: '#086e5d',
    borderRadius: 5,
    padding: 12,
    marginBottom: 20,
    color: '#fff',

  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
    textAlign: 'center',
  },
  buttonRed: {
    backgroundColor: '#a84632',
    borderRadius: 5,
    paddingHorizontal: 15,
    paddingVertical: 5,
    marginBottom: 20,
    alignSelf: 'center',
  },
  resetProgressButtonText: {
    fontSize: 8,
    color: '#fff',
  },
});

