import { useState, useEffect } from 'react';

//sector
import { useSector } from './sectorContext';

//lang
import { useTranslation } from 'react-i18next';

//raw terms
import { getTerms } from './mediaRouter';

interface Term {
    name: string;
    sector: string;
    description: string;
    translation: string;
    imgWord: string;
    imgDescription: string;
    video: string;
    videoDescription: string;
    img1: string;
    imgSource1: string;
    img2: string;
    imgSource2: string;
}

const useConvertStringToTerms = (termsString: string): [Term[], string[]] => {

    //sector
    const { sector } = useSector();

    //lang
    const { i18n } = useTranslation();

    //raw terms
    const [rawTerms, setRawTerms] = useState<Term[]>([]);

    useEffect(() => {
        const loadTerms = async () => {
            try {
                const rawTerms = await getTerms(sector, i18n.language);
                setRawTerms(rawTerms.terms);
            } catch (error) {
                console.error('Error loading terms:', error);
            }
        };
        loadTerms();
    }, [sector, i18n.language]);

    //module terms
    const [terms, setTerms] = useState<Term[]>([]);
    const [unmatchedTerms, setUnmatchedTerms] = useState<string[]>([]);

    useEffect(() => {
        const convertStringToTerms = (termsString: string): Term[] => {
            const termNames = termsString.split(',').map((name) => name.trim());

            const matchedTerms = rawTerms.filter((term) => termNames.includes(term.name));
            const unmatchedNames = termNames.filter((name) => !matchedTerms.some((term) => term.name === name));

            setUnmatchedTerms(unmatchedNames);

            return matchedTerms;
        };

        setTerms(convertStringToTerms(termsString));
    }, [termsString, rawTerms]);

    return [terms, unmatchedTerms];
};

export default useConvertStringToTerms;
