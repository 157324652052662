import React, { useState, useEffect, useMemo } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Image, Modal, Alert } from 'react-native';
import { RootTabScreenProps } from '../../types';
import { useNavigation } from '@react-navigation/native';

// lang
import { useTranslation } from 'react-i18next';

// sector
import { useSector } from '../../hooks/sectorContext';

// terms
import { getTermByName } from '../../hooks/mediaRouter';

// video
import { Video } from 'expo-av';
import { getVideoBaseURI } from '../../hooks/mediaRouter';

//progress
import { useProgress } from '../../hooks/progressContext';

interface Module {
  training_module_id: string;
  title: string;
  description: string;
  terms: Term[];
}

interface Term {
  name: string;
  sector: string;
  description: string;
  translation: string;
  imgWord: string;
  imgDescription: string;
  video: string;
  videoDescription: string;
  img1: string;
  imgSource1: string;
  img2: string;
  imgSource2: string;
  learned?: boolean;
}

const TrainingTextScreen = ({ route }: RootTabScreenProps<'TrainingText'>) => {

  //navigation
  const navigation = useNavigation();
  // sector
  const { sector } = useSector();

  // Learning progress
  const {
    getNextTerm,
    markTermAsLearned,
    getProgressForModule,
    setProgressForModule,
    learnedTerms,
  } = useProgress();

  // lang
  const { t, i18n } = useTranslation();

  const moduleId = route.params?.training_module_id as string;
  const moduleTermsString = route.params?.terms as string;
  const moduleTermsArray = useMemo(
    () => moduleTermsString.split(',').map((term) => term.trim().toLowerCase()),
    [moduleTermsString]
  );

  const currentTermName = getNextTerm(moduleId, moduleTermsArray);
  const [thisTerm, setNextTerm] = useState<Term | null>(null);
  const loadCurrentTerm = async () => {
    if (!currentTermName) {
      handleModuleCompletion(); // Call module completion if no term left
      return;
    }
  
    try {
      const term = await getTermByName(currentTermName, i18n.language, sector);
      setNextTerm(term);
    } catch (error) {
      console.error('Error loading term:', error);
    }
  };

  useEffect(() => {
    loadCurrentTerm();
  }, [currentTermName, i18n.language, sector]);

  //video
  const base_uri = getVideoBaseURI(i18n.language);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isVideoLoaded) {
        setVideoError(true);
      }
    }, 15000);
    return () => clearTimeout(timeout);
  }, [isVideoLoaded]);

  // Learning Progress
  const [evaluationOption, setEvaluationOption] = useState<string | null>(null);
  const [showEvaluation, setShowEvaluation] = useState(false);

  const isAllTermsLearned = () => {
    return moduleTermsArray.every((term) => learnedTerms.includes(term));
  };

  const handleModuleCompletion = () => {
    if (isAllTermsLearned()) {
      Alert.alert(
        t('trainingVideo.moduleCompletedTitle'),
        t('trainingVideo.moduleCompletedMessage'),
        [
          {
            text: t('trainingVideo.goBack'),
            onPress: () => navigation.goBack(),
          },
        ],
        { cancelable: false }
      );
    } else {
      navigation.goBack();
    }
  };

  const handleLearned = () => {
    if (thisTerm) {
      markTermAsLearned(thisTerm.name);
      const currentProgress = getProgressForModule(moduleId, moduleTermsArray.length);
      setProgressForModule(moduleId, currentProgress + 1);
      // Check for module completion
      if (isAllTermsLearned()) {
        handleModuleCompletion();
      } else {
        // Optionally, navigate the user to another screen or update the current term
      }
    }
  };


  const handleEvaluationFormSubmit = () => {
    if (evaluationOption === 'Lo recuerdo') {
      handleLearned();
    }
    // Navigate to the next term or finish
    if (currentTermName) {
      navigation.navigate('TrainingVideo', {
        training_module_id: moduleId,
        terms: moduleTermsString,
      });
    } else {
      navigation.goBack();
    }
    setShowEvaluation(false); // Reset showEvaluation state
  };
  

  return (
    <View style={styles.container}>
      <View style={styles.container}>
        <Text style={styles.title}>{t('trainingText.trainingTextText1')}</Text>
        <Text style={styles.termName}>{thisTerm?.name}</Text>
      </View>

      {/* Button to show term and evaluation form */}
      {!showEvaluation && (
        <TouchableOpacity
          style={styles.buttonContainer}
          onPress={() => setShowEvaluation(true)}
        >
          <Text style={styles.buttonText}>{t('trainingText.trainingTextText2')}</Text>
        </TouchableOpacity>
      )}
      {/* Show term and evaluation form */}
      {showEvaluation && (
        <>
          {/* Term name */}
          <Text style={styles.termName}>{thisTerm?.name}</Text>
          <View style={styles.videoContainer}>
            <Video
              source={{ uri: base_uri + (thisTerm?.video) }}
              style={styles.video}
              useNativeControls={isVideoLoaded}
              isLooping
              shouldPlay
              onLoad={handleVideoLoad}
              onError={() => setVideoError(true)}
            />
            {videoError && (
              <Text>
                Error: The video could not be found or did not load within 15 seconds.
              </Text>
            )}
          </View>

          {/* Evaluation form */}
          <View style={styles.evaluationForm}>
            <Text>{t('trainingVideo.trainingVideoText3')}</Text>
            <TouchableOpacity onPress={() => setEvaluationOption('No me acuerdo')} style={styles.buttonContainerR}>
              <Text>{t('training.no')}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setEvaluationOption('No recuerdo bien')} style={styles.buttonContainerY}>
              <Text>{t('training.meh')}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setEvaluationOption('Lo recuerdo')} style={styles.buttonContainerG}>
              <Text>{t('training.yes')}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.buttonContainer} onPress={handleEvaluationFormSubmit}>
              <Text style={styles.buttonText}>{t('trainingText.trainingTextNext')}</Text>
            </TouchableOpacity>
          </View>
        </>
      )}
    </View>
  );

};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 10,
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    margin: 20,
    alignItems: 'center',
    textAlignVertical: 'center'
  },
  videoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginTop: 15,
  },
  video: {
    width: '100%',
    height: 200,
  },
  buttonContainer: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 10,
    alignItems: 'center',
  },
  buttonContainerG: {
    backgroundColor: '#27AE60',
    padding: 10,
    margin: 20,
    borderRadius: 5,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1,
    elevation: 5,
  },
  buttonContainerY: {
    backgroundColor: '#F1C40F',
    padding: 10,
    margin: 20,
    borderRadius: 5,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1,
    elevation: 5,
  },
  buttonContainerR: {
    backgroundColor: '#C0392B',
    padding: 10,
    margin: 20,
    borderRadius: 5,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1,
    elevation: 5,
  },
  buttonText: {
    fontSize: 15,
    fontWeight: 'bold',
    padding: 20,
  },
  termName: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: 10,
  },
  termImage: {
    width: '100%',
    height: 200,
    marginBottom: 10,
  },
  evaluationForm: {
    backgroundColor: '#fff',
    padding: 10,
    alignItems: 'center',
  },
});

export default TrainingTextScreen;