//static language vars
export function getStaticLangLabel(language: string) {
    switch (language) {
        case 'en':
            return "English - International Sign (IS)";
        case 'it':
            return "Italiano - Lingua dei segni italiana (LIS)";
        case 'de':
            return "Deutsch - Deutsche Gebärdensprache (DGS)";
        case 'oe':
            return "Deutsch - Österreichische Gebärdensprache (ÖGS)";
        case 'es':
            return "Español - Lengua de signos española (LSE)";
        default:
            throw new Error('Invalid language');
    }
}

//static sector lang vars
export function getStaticSectorLabel(sector: string, language: string) {

    switch (sector) {
        case 'cs':
            switch (language) {
                case 'en':
                    return "Civil Servants";
                case 'it':
                    return "Funzionari";
                case 'de':
                    return "Beamtinnen und Beamte";
                case 'oe':
                    return "Beamtinnen und Beamte";
                case 'es':
                    return "Funcionarios";
            }
            break;
        case 'hs':
            switch (language) {
                case 'en':
                    return "Health Staff";
                case 'it':
                    return "Personale Sanitario";
                case 'de':
                    return "Personal im Gesundheitswesen";
                case 'oe':
                    return "Personal im Gesundheitswesen";
                case 'es':
                    return "Personal Sanitario";
            }
            break;
        case 'sp':
            switch (language) {
                case 'en':
                    return "Salespeople";
                case 'it':
                    return "Venditori";
                case 'de':
                    return "Verkäufer*innen";
                case 'oe':
                    return "Verkäufer*innen";
                case 'es':
                    return "Vendedores";
            }
            break;
        case 'tg':
            switch (language) {
                case 'en':
                    return "Tour Guides";
                case 'it':
                    return "Guide turistiche";
                case 'de':
                    return "Touristenführer*innen";
                case 'oe':
                    return "Touristenführer*innen";
                case 'es':
                    return "Guías turísticos";
            }
            break;
        case 'wa':
            switch (language) {
                case 'en':
                    return "Waiters";
                case 'it':
                    return "Camerieri";
                case 'de':
                    return "Kellner*innen";
                case 'oe':
                    return "Kellner*innen";
                case 'es':
                    return "Camareros";
            }
            break;
    }
    throw new Error('Invalid sector');
}

//static language vars
export function getSectorCourse(sector: string) {
    switch (sector) {
        case 'cs':
            return import('../lang/course/course_cs');
        default:
            throw new Error('Invalid sector');
    }
}