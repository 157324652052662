import * as React from 'react';
import { FontAwesome } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { ColorSchemeName, Pressable } from 'react-native';
import useColorScheme from '../hooks/useColorScheme';
import Colors from '../style/Colors';

//Linking to Types
import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../types';
import LinkingConfiguration from './Linking';

//Import i18n
import i18n from '../lang/i18n';

//Main Screens
import NotFoundScreen from '../screens/NotFoundScreen';
import HomeScreen from '../screens/HomeScreen';
import GlossaryScreen from '../screens/GlossaryScreen';
import TrainingScreen from '../screens/TrainingScreen';
import SettingsScreen from '../screens/SettingsScreen';

//Other Screens 
//Home
import ProjectInfoScreen from '../screens/home/ProjectInfoScreen';
import LegalNoticeScreen from '../screens/home/LegalNoticeScreen';
import DataProtectionScreen from '../screens/home/DataProtectionScreen';

//Glossary
import TermScreen from '../screens/glossary/TermScreen';

//Training
import UnitScreen from '../screens/training/UnitScreen';
import ModuleScreen from '../screens/training/ModuleScreen';
import TrainingTextScreen from '../screens/training/TrainingTextScreen';
import TrainingVideoScreen from '../screens/training/TrainingVideoScreen';

//Settings
import AppLanguageScreen from '../screens/settings/AppLanguage';
import AppSectorScreen from '../screens/settings/AppSector';

//lang
import { useTranslation } from 'react-i18next';

export default function Navigation({ colorScheme, isFirstTime }: { colorScheme: ColorSchemeName, isFirstTime: boolean | null }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DefaultTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {

  return (
    <Stack.Navigator>
      <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerShown: false }} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      {/*Home Navigation*/}
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="ProjectInfo" component={ProjectInfoScreen} options={{
          headerTitle: '',
          headerBackTitle: i18n.t('back'),
          headerTintColor: '#15af97',
        }} />
      </Stack.Group>
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="LegalNotice" component={LegalNoticeScreen} options={{
          headerTitle: '',
          headerBackTitle: i18n.t('back'),
          headerTintColor: '#15af97',
        }} />
      </Stack.Group>
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="DataProtection" component={DataProtectionScreen} options={{
          headerTitle: '',
          headerBackTitle: i18n.t('back'),
          headerTintColor: '#15af97',
        }} />
      </Stack.Group>
      {/*Glossary Navigation*/}
      <Stack.Group screenOptions={{ presentation: 'card' }}>
        <Stack.Screen name="Term" component={TermScreen} options={{
          headerTitle: '',
          headerBackTitle: i18n.t('back'),
          headerTintColor: '#15af97',
        }} />
      </Stack.Group>
      {/*Training Navigation*/}
      <Stack.Group screenOptions={{ presentation: 'card' }}>
        <Stack.Screen name="Unit" component={UnitScreen} options={{
          headerTitle: '',
          headerBackTitle: i18n.t('back'),
          headerTintColor: '#15af97',
        }} />
      </Stack.Group>
      <Stack.Group screenOptions={{ presentation: 'card' }}>
        <Stack.Screen name="Module" component={ModuleScreen} options={{
          headerTitle: '',
          headerBackTitle: i18n.t('back'),
          headerTintColor: '#15af97',
        }} />
      </Stack.Group>
      <Stack.Group screenOptions={{ presentation: 'card' }}>
        <Stack.Screen name="TrainingText" component={TrainingTextScreen} options={{
          headerTitle: '',
          headerBackTitle: i18n.t('back'),
          headerTintColor: '#15af97',
        }} />
      </Stack.Group>
      <Stack.Group screenOptions={{ presentation: 'card' }}>
        <Stack.Screen name="TrainingVideo" component={TrainingVideoScreen} options={{
          headerTitle: '',
          headerBackTitle: i18n.t('back'),
          headerTintColor: '#15af97',
        }} />
      </Stack.Group>
      {/*Setting Navigation*/}
      <Stack.Group screenOptions={{ presentation: 'card' }}>
        <Stack.Screen name="AppLanguage" component={AppLanguageScreen} options={{
          headerTitle: '',
          headerBackTitle: i18n.t('back'),
          headerTintColor: '#15af97',
        }} />
      </Stack.Group>
      <Stack.Group screenOptions={{ presentation: 'card' }}>
        <Stack.Screen name="AppSector" component={AppSectorScreen} options={{
          headerTitle: '',
          headerBackTitle: i18n.t('back'),
          headerTintColor: '#15af97',
        }} />
      </Stack.Group>
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator({ isFirstTime }: { isFirstTime: boolean | null }) {
  //lang
  const { t } = useTranslation();

  const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator
      initialRouteName={isFirstTime ? "Settings" : "Home"}
      screenOptions={{
        tabBarActiveTintColor: '#086e5d',
      }}>

      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={({ navigation }: RootTabScreenProps<'Home'>) => ({
          title: t('home.home'),
          tabBarIcon: ({ color }) => <TabBarIcon name="home" color={color} />,
        })}
      />

      <BottomTab.Screen
        name="Glossary"
        component={GlossaryScreen}
        options={({ navigation }: RootTabScreenProps<'Glossary'>) => ({
          title: t('glossary.glossary'),
          tabBarIcon: ({ color }) => <TabBarIcon name="book" color={color} />,
        })}
      />

      <BottomTab.Screen
        name="Training"
        component={TrainingScreen}
        options={({ navigation }: RootTabScreenProps<'Training'>) => ({
          title: t('training.training'),
          tabBarIcon: ({ color }) => (
            <FontAwesome5 name="chalkboard-teacher" size={24} color={color} />
          ),
        })}
      />

      <BottomTab.Screen
        name="Settings"
        component={SettingsScreen}
        options={({ navigation }: RootTabScreenProps<'Settings'>) => ({
          title: t('settings.settings'),
          tabBarIcon: ({ color }) => <TabBarIcon name="gear" color={color} />,
        })}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}
