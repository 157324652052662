import React, { useState } from 'react';
import { Platform, Text, View, StyleSheet, TouchableOpacity, TextInput, Image, FlatList } from 'react-native';

//navigation
import { useNavigation, useRoute } from '@react-navigation/native';
import { TrainingScreenNavigationProp, ModuleScreenRouteProp } from '../../types';
import { RootTabScreenProps } from '../../types';


//Modules
import { CS_EN_MODULES } from '../data/language/en_dummy_modules';

export default function UnitScreen({ }: RootTabScreenProps<'Unit'>) {

    //navigation
    const navigation = useNavigation<TrainingScreenNavigationProp>();
    const route = useRoute<ModuleScreenRouteProp>();


    /*Render JSX*/
    return (
        <View style={styles.screenContainer}>
        </View>
    );
};


const styles = StyleSheet.create({
    screenContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        padding: 15,
        margin: 15,
    },
    screenTitleText: {
        fontSize: 25,
        fontWeight: 'bold',
        padding: 10,
        margin: 10,
        width: '70%',
        textAlign: 'center'
    },
    screenDescriptionText: {
        fontSize: 20,
        padding: 5,
        margin: 5
    },
    sectionContainer: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        padding: 5,
        margin: 5,
    },
    sectionTitleText: {
        fontSize: 20,
        fontWeight: 'bold',
        padding: 2,
        margin: 2
    },
    sectionDescriptionText: {
        fontSize: 15,
        padding: 2,
        margin: 2
    },
    buttonContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        textAlignVertical: 'center'
    },
    buttonText: {
        fontSize: 15,
        fontWeight: 'bold',
        padding: 20,
    },
    buttonListContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 200,
        padding: 15,
        marginTop: 15,
        backgroundColor: '#cacaca50',
        borderRadius: 10,
        textAlignVertical: 'center'
    },
    buttonListTitle: {
        fontSize: 25,
        fontWeight: 'bold',
        padding: 10,
        margin: 10,
    },
    buttonListDescription: {
        padding: 5,
    },
    searchbar: {
        backgroundColor: '#fafafa',
        marginStart: 10,
        marginEnd: 10,
        marginVertical: 10,
        borderRadius: 10,
        padding: 10
    },
    textinput: {
        backgroundColor: '#fafafa',
        paddingHorizontal: 20
    }
});