interface Term {
    name: string;
    sector: string;
    description: string;
    translation: string;
    imgWord: string;
    imgDescription: string;
    video: string;
    videoDescription: string;
    img1: string;
    imgSource1: string;
    img2: string;
    imgSource2: string;
}

interface Progress {
    termName: string;
    learned: boolean;
}

const useCompletionPercentage = (progress: Progress[], terms: Term[]): number => {
    return (progress.filter((p) => p.learned).length / terms.length) * 100;
};

export default useCompletionPercentage;
