/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
    prefixes: [Linking.createURL('/')],
    config: {
        screens: {
            Root: {
                screens: {
                    Home: {
                        screens: {
                            HomeScreen: 'Home',
                            DataProtectionScreen: 'DataProtection',
                            LegalNoticeScreen: 'LegalNotice',
                            ProjectInfoScreen: 'ProjectInfo'
                        },
                    },
                    Glossary: {
                        screens: {
                            GlossaryScreen: 'Glossary',
                            TermScreen: 'Term'
                        },
                    },
                    Training: {
                        screens: {
                            TrainingScreen: 'Training',
                            UnitScreen: 'Unit',
                            ModuleScreen: 'Module',
                            TrainingTextScreen: 'TrainingText',
                            TrainingVideoScreen: 'TrainingVideo',
                        },
                    },
                    Settings: {
                        screens: {
                            SettingScreen: 'Settings',
                            AppLanguageScreen: 'AppLanguage'
                        },
                    },
                },
            },
            Modal: 'modal',
            NotFound: '*',
        },
    },
};

export default linking;
