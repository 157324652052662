import React, { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './hooks/UseCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation/Navigator';

import i18n from './lang/i18n';
import { I18nextProvider } from 'react-i18next';
import { SectorProvider } from './hooks/sectorContext';
import { ProgressProvider } from './hooks/progressContext';

export default function App() {

  //Se asegura que se carga
  const isLoadingComplete = useCachedResources();

  //Carga el esquema de color en el navegador
  const colorScheme = useColorScheme();

  async function checkFirstTime(): Promise<boolean> {
    const isFirstTime = await AsyncStorage.getItem('firstTime');
    if (isFirstTime === null) {
      await AsyncStorage.setItem('firstTime', 'false');
      return true;
    }
    return false;
  }

  const [isFirstTime, setIsFirstTime] = useState<boolean | null>(null);

  useEffect(() => {
    const check = async () => {
      const isFirst = await checkFirstTime();
      setIsFirstTime(isFirst);
    };
    check();
  }, []);



  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SectorProvider>
        <ProgressProvider>
          <I18nextProvider i18n={i18n}>
            <SafeAreaProvider>
              <Navigation colorScheme={colorScheme} isFirstTime={isFirstTime} />
              <StatusBar />
            </SafeAreaProvider>
          </I18nextProvider>
        </ProgressProvider>
      </SectorProvider>
    );
  }
}