import { useSector } from './sectorContext';

interface Term {
    name: string;
    sector: string;
    description: string;
    translation: string;
    imgWord: string;
    imgDescription: string;
    video: string;
    videoDescription: string;
    img1: string;
    imgSource1: string;
    img2: string;
    imgSource2: string;
    learned?: boolean;
}

//video folder
export function getVideoBaseURI(language: string) {
    switch (language) {
        case 'en':
            return "http://swinglearning.infoproject.eu/resources/languageEN/videos/";
        case 'it':
            return "http://swinglearning.infoproject.eu/resources/languageITA/videos/";
        case 'de':
            return "http://swinglearning.infoproject.eu/resources/languageALE/videos/";
        case 'oe':
            return "http://swinglearning.infoproject.eu/resources/languageAUS/videos/";
        default:
            return "http://swinglearning.infoproject.eu/resources/languageESP/videos/";
    }
}

export function getCourse(sector: string, language: string) {
    switch (sector) {
        case 'cs':
            switch (language) {
                case 'en':
                    return import('../lang/course/en/course_cs_en');
                case 'it':
                    return import('../lang/course/it/course_cs_it');
                case 'de':
                    return import('../lang/course/de/course_cs_de');
                case 'oe':
                    return import('../lang/course/oe/course_cs_oe');
                case 'es':
                    return import('../lang/course/es/course_cs_es');
            }
            break;
        case 'hs':
            switch (language) {
                case 'en':
                    return import('../lang/course/en/course_hs_en');
                case 'it':
                    return import('../lang/course/it/course_hs_it');
                case 'de':
                    return import('../lang/course/de/course_hs_de');
                case 'oe':
                    return import('../lang/course/oe/course_hs_oe');
                case 'es':
                    return import('../lang/course/es/course_hs_es');
            }
            break;
        case 'sp':
            switch (language) {
                case 'en':
                    return import('../lang/course/en/course_sp_en');
                case 'it':
                    return import('../lang/course/it/course_sp_it');
                case 'de':
                    return import('../lang/course/de/course_sp_de');
                case 'oe':
                    return import('../lang/course/oe/course_sp_oe');
                case 'es':
                    return import('../lang/course/es/course_sp_es');
            }
            break;
        case 'tg':
            switch (language) {
                case 'en':
                    return import('../lang/course/en/course_tg_en');
                case 'it':
                    return import('../lang/course/it/course_tg_it');
                case 'de':
                    return import('../lang/course/de/course_tg_de');
                case 'oe':
                    return import('../lang/course/oe/course_tg_oe');
                case 'es':
                    return import('../lang/course/es/course_tg_es');
            }
            break;
        case 'wa':
            switch (language) {
                case 'en':
                    return import('../lang/course/en/course_wa_en');
                case 'it':
                    return import('../lang/course/it/course_wa_it');
                case 'de':
                    return import('../lang/course/de/course_wa_de');
                case 'oe':
                    return import('../lang/course/oe/course_wa_oe');
                case 'es':
                    return import('../lang/course/es/course_wa_es');
            }
            break;
    }
    throw new Error('Invalid sector or language');
}

export function getTerms(sector: string, language: string) {
    switch (sector) {
        case 'cs':
            switch (language) {
                case 'en':
                    return import('../lang/sector/cs/cs_en');
                case 'it':
                    return import('../lang/sector/cs/cs_it');
                case 'de':
                    return import('../lang/sector/cs/cs_de');
                case 'oe':
                    return import('../lang/sector/cs/cs_oe');
                case 'es':
                    return import('../lang/sector/cs/cs_es');
            }
            break;
        case 'hs':
            switch (language) {
                case 'en':
                    return import('../lang/sector/hs/hs_en');
                case 'it':
                    return import('../lang/sector/hs/hs_it');
                case 'de':
                    return import('../lang/sector/hs/hs_de');
                case 'oe':
                    return import('../lang/sector/hs/hs_oe');
                case 'es':
                    return import('../lang/sector/hs/hs_es');
            }
            break;
        case 'sp':
            switch (language) {
                case 'en':
                    return import('../lang/sector/sp/sp_en');
                case 'it':
                    return import('../lang/sector/sp/sp_it');
                case 'de':
                    return import('../lang/sector/sp/sp_de');
                case 'oe':
                    return import('../lang/sector/sp/sp_oe');
                case 'es':
                    return import('../lang/sector/sp/sp_es');
            }
            break;
        case 'tg':
            switch (language) {
                case 'en':
                    return import('../lang/sector/tg/tg_en');
                case 'it':
                    return import('../lang/sector/tg/tg_it');
                case 'de':
                    return import('../lang/sector/tg/tg_de');
                case 'oe':
                    return import('../lang/sector/tg/tg_oe');
                case 'es':
                    return import('../lang/sector/tg/tg_es');
            }
            break;
        case 'wa':
            switch (language) {
                case 'en':
                    return import('../lang/sector/wa/wa_en');
                case 'it':
                    return import('../lang/sector/wa/wa_it');
                case 'de':
                    return import('../lang/sector/wa/wa_de');
                case 'oe':
                    return import('../lang/sector/wa/wa_oe');
                case 'es':
                    return import('../lang/sector/wa/wa_es');
            }
            break;
    }
    throw new Error('Invalid sector or language');
}

export async function getTermByName(termName: string, language: string, sector: string): Promise<Term | null> {
    try {
      const termsModule = await getTerms(sector, language);
      const term = termsModule.terms.find((t) => t.name.toLowerCase() === termName.toLowerCase());
  
      if (term) {
        return term;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error loading term:', error);
      throw error;
    }
  }
  

