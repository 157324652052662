import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { TrainingScreenNavigationProp } from '../types';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

interface Unit {
    training_unit_id: string;
    training_unit_name: string;
    modules: Module[];
}

interface Module {
    training_module_id: string;
    title: string;
    description: string;
    terms: string;
}

interface UnitModuleProps {
    unit: Unit;
}

const UnitModule: React.FC<UnitModuleProps> = ({ unit }) => {

    const navigation = useNavigation<TrainingScreenNavigationProp>();

    const { t } = useTranslation()

    return (
        <View key={unit.training_unit_id} style={styles.unitContainer}>
            <View style={styles.modalView}>
                <Text style={styles.modalDescription}>{unit.training_unit_name}</Text>
            </View>

            {unit.modules.map((module) => {
                return (
                    <View key={module.training_module_id} style={styles.container}>
                        <TouchableOpacity
                            style={styles.termItem}
                            onPress={() =>
                                navigation.navigate('Module', {
                                    training_module_id: module.training_module_id,
                                    title: module.title,
                                    description: module.description,
                                    terms: module.terms,
                                })
                            }
                        >
                            <Text style={styles.termName}>{module.title}</Text>
                            <Text style={styles.termDescription}>{module.description}</Text>
                        </TouchableOpacity>
                    </View>
                );
            })}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        padding: 10,
    },
    modalView: {
        backgroundColor: '#15af97',
        padding: 20,
        borderRadius: 20,
        marginBottom: 20,
    },
    modalDescription: {
        fontSize: 25,
        fontWeight: 'bold',
        marginBottom: 15,
        textAlign: 'center',
        color: 'white',
    },
    unitContainer: {
        marginBottom: 15,
        overflow: 'hidden',
    },
    debugMessage: {
        fontSize: 16,
        color: '#000',
        textAlign: 'center',
        marginTop: 20,
    },
    termItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#15af97',
    },
    termName: {
        padding: 5,
        fontSize: 20,
        fontWeight: 'bold'
    },
    termDescription: {
        padding: 5,
        fontSize: 15
    }
});

export default UnitModule;
