import React, { useState } from 'react';
import { Platform, Text, View, StyleSheet } from 'react-native';

import { StatusBar } from 'expo-status-bar';

import { useRoute } from '@react-navigation/native';
import { LegalNoticeScreenRouteProp } from '../../types';


const LegalNoticeScreen = () => {

  const route = useRoute<LegalNoticeScreenRouteProp>();
  
  return (
    <View style={styles.screenContainer}>

      <Text style={styles.screenTitleText}>
      </Text>

      <Text style={styles.screenDescriptionText}>
      </Text>

      <Text style={styles.screenDescriptionText}>
      </Text>

      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
    </View>
  );
}

export default LegalNoticeScreen;

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    margin: 10
  },
  screenTitleText: {
    fontSize: 25,
    fontWeight: 'bold',
    padding: 7,
    margin: 7
  },
  screenDescriptionText: {
    fontSize: 20,
    padding: 5,
    margin: 5
  },
  sectionContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    margin: 10,
  },
  sectionTitleText: {
    fontSize: 20,
    fontWeight: 'bold',
    padding: 2,
    margin: 2
  },
  sectionDescriptionText: {
    fontSize: 15,
    padding: 2,
    margin: 2
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    height: 100,
    width: 300,
    padding: 5,
    margin: 5,
    backgroundColor: '#cacaca50',
    borderRadius: 10,
    textAlignVertical: 'center'
  },
  buttonText: {
    fontSize: 15,
    fontWeight: 'bold',
    padding: 20,
  },
  pickerContainer: {
    width: '100%',
    padding: 10,
    backgroundColor: '#c0c0c0',
    margin: 10,
    borderRadius: 10,
  },
});