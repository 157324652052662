import React, { createContext, useContext, useState } from 'react';

interface ModuleProgress {
  moduleId: string;
  progress: number;
}

interface ProgressContextData {
  progressData: ModuleProgress[];
  learnedTerms: string[];
  getProgressForModule: (moduleId: string) => number;
  setProgressForModule: (moduleId: string, progress: number) => void;
  getNextTerm: (moduleId: string, terms: string[]) => string | null;
  markTermAsLearned: (termId: string) => void;
}

const ProgressContext = createContext<ProgressContextData | undefined>(undefined);

export const ProgressProvider: React.FC = ({ children }) => {
  const [progressData, setProgressData] = useState<ModuleProgress[]>([]);
  const [learnedTerms, setLearnedTerms] = useState<string[]>([]);

  const getProgressForModule = (moduleId: string) => {
    const moduleProgress = progressData.find((progress) => progress.moduleId === moduleId);
    return moduleProgress?.progress || 0;
  };

  const setProgressForModule = (moduleId: string, progress: number) => {
    const updatedProgressData = progressData.map((moduleProgress) =>
      moduleProgress.moduleId === moduleId ? { ...moduleProgress, progress } : moduleProgress
    );
    const moduleExists = updatedProgressData.some((moduleProgress) => moduleProgress.moduleId === moduleId);
    if (!moduleExists) {
      updatedProgressData.push({ moduleId, progress });
    }
    setProgressData(updatedProgressData);
  };

  const getNextTerm = (moduleId: string, terms: string[]): string | null => {
    const moduleProgress = getProgressForModule(moduleId);
    const notLearnedTerms = terms.filter((term) => !learnedTerms.includes(term));
    return notLearnedTerms.length > moduleProgress ? notLearnedTerms[moduleProgress] : null;
  };
  

  const markTermAsLearned = (termId: string) => {
    setLearnedTerms((prevLearnedTerms) => [...prevLearnedTerms, termId]);
  };

  return (
    <ProgressContext.Provider value={{ progressData, learnedTerms, getProgressForModule, setProgressForModule, getNextTerm, markTermAsLearned }}>
      {children}
    </ProgressContext.Provider>
  );
};

export function useProgress() {
  const context = useContext(ProgressContext);
  if (context === undefined) {
    throw new Error('useProgress must be used within a ProgressProvider');
  }
  return context;
}

